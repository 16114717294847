import Vue from 'vue';
import Router from 'vue-router';
import store from '../store';
// TODO: Evaluate if this is needed
// import { vuexOidcCreateRouterMiddleware } from 'vuex-oidc';

import RouteHandler from '../RouteHandler';

Vue.use(Router);

// support languages in the URL prefix
// e.g. /da-DK/path, or /en/path, or /path
export const routePatterns = [
  '/:lang([a-z]{2}-[A-Z]{2})/:sitecoreRoute*',
  '/:lang([a-z]{2})/:sitecoreRoute*',
  '/:sitecoreRoute*',
];

export function createRouter() {
  // create an instance of vue-router and configure routes to use the RouteHandler component
  const router = new Router({
    mode: 'history',
    routes: routePatterns.map((routePattern) => {
      return {
        path: routePattern,
        component: RouteHandler,
        props: (route) => ({
          route,
        }),
        // TODO: Evaluate if this is needed
        // // needed for vuexOidcCreateRouterMiddleware
        // meta: {
        //   isPublic: true,
        // },
      };
    }),
  });

  // TODO: Evaluate if this is needed
  // router.beforeEach(vuexOidcCreateRouterMiddleware(store, 'oidcStore'));

  router.beforeEach((to, from, next) => {
    const basePath = store.getters['app/getBasePath'];
    // check if we are in the middle of getting redirected from oidc login
    const validOrderflow = basePath && !to.path.endsWith('redirect');
    if (validOrderflow && !to.path.includes(basePath)) store.dispatch('config/reloadConfig');

    // TODO: maybe the step path should be loaded from config instead of hardcoding
    if (to.path.includes('/abonnement')) {
      store.dispatch('app/addItem', { key: 'goBackToStep', value: from.path });
      console.log(`router: Saved value: '${from.path}' for key: 'goBackToStep'`);
    }

    next();
  });

  return router;
}
