/**
 * @param {string} validatorName The name of the validator from Sitecore
 * @param {string} value The value to check against the custom validator
 * @returns {boolean} The result of the selected validator True | False
 */
export const validateChecksum = (validatorName, value) => {
  switch (validatorName) {
    case 'Norwegian':
      return NorwegianCheckSum(value);
    default:
      return true;
  }
};

// Private functions
function NorwegianCheckSum(rewardNumber) {
  const len = rewardNumber.length;
  const rewardNumberWithoutCheckDigit = rewardNumber.substring(0, len - 1);
  const checkDigit = rewardNumber.substring(len - 1, len);
  const calculatedCheckDigit = calculateRewardNumberCheckDigit(rewardNumberWithoutCheckDigit);
  return checkDigit === calculatedCheckDigit;
}

// This is copied code from norwegian documentaion
// https://norwegian-reward.readme.io/docs/other-features
function calculateRewardNumberCheckDigit(val) {
  const checksum = new Array();
  let odd = false;
  let increment = 0;
  let i;
  for (i = 0; i < val.length; i++) {
    const t = val.substring(i, i + 1);
    if (odd) {
      odd = false;
      const intCheck = parseInt(t);
      const oddCheck = (intCheck * 2).toString();
      for (let j = 0; j < oddCheck.length; j++) {
        checksum[increment++] = oddCheck.substring(j, j + 1);
      }
    } else {
      odd = true;
      checksum[increment++] = t;
    }
  }
  let sumInput = 0;
  let sumChecksum = 0;
  for (i = 0; i < val.length; i++) {
    const t2 = val.substring(i, i + 1);
    const currentInt = parseInt(t2);
    sumInput = sumInput + currentInt;
  }
  for (i = 0; i < checksum.length; i++) {
    if (parseInt(checksum[i])) {
      sumChecksum = sumChecksum + parseInt(checksum[i]);
    }
  }
  const topRes = sumChecksum + 10;
  const divRes = Math.floor(topRes / 10);
  const sumRes = divRes * 10;
  let calcCheckSum = sumRes - sumChecksum;
  // if the highest number 10 is 10 then we force it to 0 to avoid number
  // overflow
  if (calcCheckSum == 10) {
    calcCheckSum = 0;
  }
  return calcCheckSum.toString();
}
