export function isRequired(value) {
  if (typeof value == 'boolean') return value;
  if (typeof value == 'string') return value.trim() !== '';
  else return value !== undefined && value !== null;
}

export function isEmail(value) {
  return String(value)
    .toLowerCase()
    .match(
      // eslint-disable-next-line
      /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i
    );
}

export function isBirthday(value) {
  const matchString = String(value).match(/^(0[1-9]|[1-2][0-9]|3[0-1])\.(0[1-9]|1[0-2])\.\d{4}$/);
  // get current date to compare that the value is in the past (and the person is existing)
  if (!matchString) return false;

  const today = new Date();

  // we need to extract the matchstring and put it into a actual date format
  const birthdayDate = parseInt(matchString[1], 10);
  const birthDayMonth = parseInt(matchString[2], 10);
  const birthDayYear = matchString[0].slice(-4);

  let birthDate = new Date();
  birthDate.setDate(birthdayDate);
  birthDate.setMonth(birthDayMonth - 1);
  birthDate.setUTCFullYear(birthDayYear);
  if (birthDate > today) return false;

  return true;
}
