<template>
  <div class="recruitment" v-if="visible">
    <div class="check-box">
      <input type="checkbox" id="recruitment" name="recruitment" v-model="checkboxValue" class="check-box__input" />
      <div class="check-box__content">
        <ScText
          for="recruitment"
          class="check-box__content-label text-s text-md-s p-l-xxs"
          tag="label"
          :field="fields.RecruitmentCheckboxText"
        />
      </div>
    </div>

    <div
      class="form-field form-field--split recruitment__input-field input-field--small"
      :class="[
        checkboxValue == true ? 'isOpen' : 'isClosed',
        recruitmentCodeActivated == true ? 'code-active' : '',
        showCodeError && checkboxValue ? 'validate-error' : '',
      ]"
    >
      <div class="form-field__input form-field--small m-b--clear p-b-xxs" v-if="!recruitmentCodeActivated">
        <ScText for="recruitmentcode" tag="label" :field="fields.RecruitmentLabelText" class="text-s text-md-s text-bold" />
      </div>
      <div class="form-field__input input-field__content" v-if="!recruitmentCodeActivated">
        <input
          inputmode="numeric"
          autocapitalize="none"
          autocorrect="off"
          id="recruitmentcode"
          name="recruitmentcode"
          placeholder=""
          type="text"
          class="input-field__content-input text-s text-md-s"
          v-model="code"
          @keydown.enter="addCode()"
        />
        <button type="button" class="button-textlink button-small button-hover" @click="addCode">
          <span class="main">
            <ScText class="text-normal text-sm-s text-md-m" tag="span" :field="fields.RecruitmentSubmitText" />
          </span>
        </button>
      </div>

      <div class="recruitment-message__container" v-if="recruitmentCodeActivated">
        <p class="recruitment-message recruitment-message--success text-sm-s text-md-s">{{ activatedText }}</p>
        <button class="button-textlink button-small button-hover" @click="removeCode">
          <span class="main">
            <ScText class="text-normal text-sm-s text-md-m" tag="span" :field="fields.RecruitmentRemoveText" />
          </span>
        </button>
      </div>
    </div>
    <ScText
      class="recruitment-message--error text-xs text-md-xs validation-text validation-error-text text-s"
      tag="p"
      :field="fields.RecruitmentCodeErrorText"
      v-if="showCodeError && checkboxValue"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { validateRecruitmentCode } from '@/api';
import { Text } from '@sitecore-jss/sitecore-jss-vue';
import { gtmSharedParams } from '@/gtmTracking';
import { gtmAddCouponEvent } from '../../../../Talkmore.Web.Vue.Shared/src/utils/gtmTracking';

export default {
  name: 'Recruitment',
  components: {
    ScText: Text,
  },
  inject: {
    saveRecruitmentCode: {
      type: Function,
    },
  },
  props: {
    fields: {
      type: Object,
    },
  },
  data() {
    return {
      checkboxValue: this.$store.state.app.recruitmentCode != null,
      recruitmentCodeActivated: this.$store.state.app.recruitmentCode != null,
      code: this.$store.state.app.recruitmentCode,
      showCodeError: false,
      showCodeSuccess: false,
    };
  },
  computed: {
    ...mapGetters({
      isRecruitmentOrderType: 'config/isRecruitmentOrderType',
    }),
    activatedText() {
      if (this.fields.RecruitmentCodeActivatedText?.value.includes('{code}')) {
        return this.fields.RecruitmentCodeActivatedText?.value.replace('{code}', this.code);
      }
      return this.fields.RecruitmentCodeActivatedText?.value;
    },
    isSomeonePorting() {
      let isPorting = false;
      const items = this.$store.getters['basket/getItems'];
      items.forEach((item) => {
        if (item.user?.porting === 'keep-number') isPorting = true;
      });
      return isPorting;
    },
    isRecruitmentFlowStarted() {
      return this.$store.state.app?.isRecruitmentFlowStarted;
    },
    visible() {
      return this.isSomeonePorting && !this.isRecruitmentOrderType;
    },
  },
  watch: {
    checkboxValue() {
      if (this.checkboxValue === false) {
        this.removeCode();
      }
    },
    showCodeError(newVal) {
      if (newVal === true) this.$root.$emit('onErrorMessage', this.fields.RecruitmentCodeErrorText.value);
    },
  },
  mounted() {
    if (!this.isSomeonePorting && this.code && !this.isRecruitmentFlowStarted) this.removeCode(); // TODO: clarify if the code should be removed
  },
  methods: {
    addCode() {
      if (!this.code) {
        this.showCodeError = false;
        this.showCodeSuccess = false;
        return;
      }
      this.checkRecruitmentCode(this.code);
    },
    async checkRecruitmentCode(code) {
      try {
        const response = await validateRecruitmentCode(code);

        if (!response.data) {
          console.error(`${this.$options.name}: Could not validate recruitment code`);
          this.recruitmentCodeActivated = false;
          this.showCodeError = true;
          this.showCodeSuccess = false;
          return;
        }

        const { isValid } = response.data;

        this.showCodeError = !isValid;
        this.showCodeSuccess = isValid;
        this.recruitmentCodeActivated = isValid;
        if (isValid) {
          await this.saveRecruitmentCode(code);
          gtmAddCouponEvent({ coupon: code, gtmSharedParams: gtmSharedParams() });
        }
      } catch (error) {
        console.log(error);
        this.recruitmentCodeActivated = false;
        this.showCodeError = true;
      }
    },
    removeCode() {
      this.showCodeSuccess = false;
      this.recruitmentCodeActivated = false;
      this.code = null;
      this.showCodeError = false;
      this.$store.dispatch('app/deleteItem', 'recruitmentCode');
      console.log(`${this.$options.name}: Cleared value for key: 'recruitmentCode'`);
    },
  },
};
</script>

<style lang="scss" scoped>
.recruitment {
  padding-top: $spacing-s;
  margin-top: $spacing-s;
  border-top: 1px solid $color-grey-tint;

  @include screen-tablet-portrait-up {
    padding-top: $spacing-m;
    margin-top: $spacing-m;
  }
  .check-box {
    margin-bottom: 0;
    display: flex;
    align-items: center;
  }
  &__input-field {
    display: flex;
    width: 100%;
    overflow: hidden;
    margin-bottom: 0;
    height: 0;
    overflow-y: clip;
    overflow-x: visible;
    flex-direction: column;

    &.isOpen {
      visibility: visible;
      height: 95px;
      transition: ease-in-out all 0.25s;
      padding-top: $spacing-s;
      &.validate-error {
        height: auto !important;
        overflow: visible;
      }
    }
    &.isClosed {
      visibility: hidden;
      height: 0;
      transition: ease-in-out all 0.25s;
    }

    &.code-active {
      height: auto;
    }
  }
  .recruitment-message {
    &--error {
      color: $color-red;
      padding-top: $spacing-xxs;
    }
    &--success {
      color: $color-green-darker;
    }

    &__container {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding-top: $spacing-xxs;

      button {
        padding-right: 12px;
        margin: -8px -12px -8px 0;
      }
    }
  }
}
</style>
