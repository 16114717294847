<template>
  <div class="dev">
    <template v-if="showMulti && !isPartnerFlow">
      <div class="steps">
        <router-link :to="`${multiPath}`">multi</router-link>
        <router-link :to="`${multiPath}/abonnement`">abonnement</router-link>
        <router-link :to="`${multiPath}/eier`">eier</router-link>
        <router-link :to="`${multiPath}/brukere`">brukere</router-link>
        <router-link :to="`${multiPath}/sikkerhet`">sikkerhet</router-link>
        <router-link :to="`${multiPath}/bekreft`">bekreft</router-link>
        <router-link :to="`${multiPath}/kvittering`">kvittering</router-link>
      </div>
      <div class="actions">
        <a @click="loadMultiStore">load store</a>
        <router-link :to="redirectPath">redirect</router-link>
        <router-link :to="basePath">single</router-link>
        <router-link :to="familyPath">family</router-link>
      </div>
      <div class="flows">
        <router-link :to="basePath">enkelt</router-link>
        <router-link :to="`${partnerPathPf}/default`">pensjonistforbundet</router-link>
        <router-link :to="`${partnerPathObos}/default`">obos</router-link>
      </div>
    </template>

    <template v-else-if="showFamily && !isPartnerFlow">
      <div class="steps">
        <router-link :to="`${familyPath}`">family</router-link>
        <router-link :to="`${familyPath}/eier`">eier</router-link>
        <router-link :to="`${familyPath}/familien`">familien</router-link>
        <router-link :to="`${familyPath}/startdato`">startdato</router-link>
        <router-link :to="`${familyPath}/sikkerhet`">sikkerhet</router-link>
        <router-link :to="`${familyPath}/bekreft`">bekreft</router-link>
        <router-link :to="`${familyPath}/kvittering`">kvittering</router-link>
      </div>
      <div class="actions">
        <a @click="loadFamilyStore">load store</a>
        <router-link :to="redirectPath">redirect</router-link>
        <router-link :to="basePath">single</router-link>
        <router-link :to="multiPath">multi</router-link>
      </div>
      <div class="flows">
        <router-link :to="basePath">enkelt</router-link>
        <router-link :to="`${partnerPathPf}/default`">pensjonistforbundet</router-link>
        <router-link :to="`${partnerPathObos}/default`">obos</router-link>
      </div>
    </template>

    <template v-else-if="showPartnerPF && isPartnerFlow">
      <div class="steps">
        <router-link :to="`${partnerPathPf}/default`">default</router-link>
        <router-link :to="`${partnerPathPf}/default/abonnement`">abonnement</router-link>
        <router-link :to="`${partnerPathPf}/default/eier`">eier</router-link>
        <router-link :to="`${partnerPathPf}/default/bruker`">bruker</router-link>
        <router-link :to="`${partnerPathPf}/default/startdato`">startdato</router-link>
        <router-link :to="`${partnerPathPf}/default/sikkerhet`">sikkerhet</router-link>
        <router-link :to="`${partnerPathPf}/default/bekreft`">bekreft</router-link>
        <router-link :to="`${partnerPathPf}/default/kvittering`">kvittering</router-link>
      </div>
      <div class="actions">
        <a @click="loadSingleStore">load store</a>
        <router-link :to="redirectPath">redirect</router-link>
        <router-link :to="`${partnerPathObos}/default`">single</router-link>
        <router-link :to="`${partnerPathObos}/multi`">multi</router-link>
        <router-link :to="`${partnerPathObos}/family`">family</router-link>
      </div>
      <div class="flows">
        <!-- <router-link :to="partnerPathPf">pensjonistforbundet</router-link> -->
        <router-link :to="basePath">enkelt</router-link>
        <router-link :to="`${partnerPathPf}/default`">pensjonistforbundet</router-link>
        <router-link :to="`${partnerPathObos}/default`">obos</router-link>
      </div>
    </template>

    <template v-else-if="showPartnerObos && isPartnerFlow && showFamily">
      <div class="steps">
        <router-link :to="`${partnerPathObos}/family`">default</router-link>
        <router-link :to="`${partnerPathObos}/family/abonnement`">abonnement</router-link>
        <router-link :to="`${partnerPathObos}/family/eier`">eier</router-link>
        <router-link :to="`${partnerPathObos}/family/bruker`">bruker</router-link>
        <router-link :to="`${partnerPathObos}/family/startdato`">startdato</router-link>
        <router-link :to="`${partnerPathObos}/family/sikkerhet`">sikkerhet</router-link>
        <router-link :to="`${partnerPathObos}/family/bekreft`">bekreft</router-link>
        <router-link :to="`${partnerPathObos}/family/kvittering`">kvittering</router-link>
      </div>
      <div class="actions">
        <a @click="loadSingleStore">load store</a>
        <router-link :to="redirectPath">redirect</router-link>
        <router-link :to="`${partnerPathObos}/default`">single</router-link>
        <router-link :to="`${partnerPathObos}/multi`">multi</router-link>
        <router-link :to="`${partnerPathObos}/family`">family</router-link>
      </div>
      <div class="flows">
        <router-link :to="basePath">enkelt</router-link>
        <router-link :to="`${partnerPathPf}/default`">pensjonistforbundet</router-link>
        <router-link :to="`${partnerPathObos}/default`">obos</router-link>
      </div>
    </template>

    <template v-else-if="showPartnerObos && isPartnerFlow && showMulti">
      <div class="steps">
        <router-link :to="`${partnerPathObos}/multi`">default</router-link>
        <router-link :to="`${partnerPathObos}/multi/abonnement`">abonnement</router-link>
        <router-link :to="`${partnerPathObos}/multi/eier`">eier</router-link>
        <router-link :to="`${partnerPathObos}/multi/bruker`">bruker</router-link>
        <router-link :to="`${partnerPathObos}/multi/startdato`">startdato</router-link>
        <router-link :to="`${partnerPathObos}/multi/sikkerhet`">sikkerhet</router-link>
        <router-link :to="`${partnerPathObos}/multi/bekreft`">bekreft</router-link>
        <router-link :to="`${partnerPathObos}/multi/kvittering`">kvittering</router-link>
      </div>
      <div class="actions">
        <a @click="loadSingleStore">load store</a>
        <router-link :to="redirectPath">redirect</router-link>
        <router-link :to="`${partnerPathObos}/default`">single</router-link>
        <router-link :to="`${partnerPathObos}/multi`">multi</router-link>
        <router-link :to="`${partnerPathObos}/family`">family</router-link>
      </div>
      <div class="flows">
        <router-link :to="basePath">enkelt</router-link>
        <router-link :to="`${partnerPathPf}/default`">pensjonistforbundet</router-link>
        <router-link :to="`${partnerPathObos}/default`">obos</router-link>
      </div>
    </template>

    <template v-else-if="showPartnerObos && isPartnerFlow">
      <div class="steps">
        <router-link :to="`${partnerPathObos}/default`">default</router-link>
        <router-link :to="`${partnerPathObos}/default/abonnement`">abonnement</router-link>
        <router-link :to="`${partnerPathObos}/default/eier`">eier</router-link>
        <router-link :to="`${partnerPathObos}/default/bruker`">bruker</router-link>
        <router-link :to="`${partnerPathObos}/default/startdato`">startdato</router-link>
        <router-link :to="`${partnerPathObos}/default/sikkerhet`">sikkerhet</router-link>
        <router-link :to="`${partnerPathObos}/default/bekreft`">bekreft</router-link>
        <router-link :to="`${partnerPathObos}/default/kvittering`">kvittering</router-link>
      </div>
      <div class="actions">
        <a @click="loadSingleStore">load store</a>
        <router-link :to="redirectPath">redirect</router-link>
        <router-link :to="`${partnerPathObos}/default`">single</router-link>
        <router-link :to="`${partnerPathObos}/multi`">multi</router-link>
        <router-link :to="`${partnerPathObos}/family`">family</router-link>
      </div>
      <div class="flows">
        <router-link :to="basePath">enkelt</router-link>
        <router-link :to="`${partnerPathPf}/default`">pensjonistforbundet</router-link>
        <router-link :to="`${partnerPathObos}/default`">obos</router-link>
      </div>
    </template>

    <template v-else>
      <div class="steps">
        <router-link :to="`${basePath}`">default</router-link>
        <router-link :to="`${basePath}/abonnement`">abonnement</router-link>
        <router-link :to="`${basePath}/eier`">eier</router-link>
        <router-link :to="`${basePath}/bruker`">bruker</router-link>
        <router-link :to="`${basePath}/startdato`">startdato</router-link>
        <router-link :to="`${basePath}/sikkerhet`">sikkerhet</router-link>
        <router-link :to="`${basePath}/bekreft`">bekreft</router-link>
        <router-link :to="`${basePath}/kvittering`">kvittering</router-link>
      </div>
      <div class="actions">
        <a @click="setTrumfId">set trumfId</a>
        <a @click="deleteTrumfId">delete trumfId</a>
        <a @click="loadSingleStore">load store</a>
        <router-link :to="redirectPath">redirect</router-link>
        <router-link :to="multiPath">multi</router-link>
        <router-link :to="familyPath">family</router-link>
      </div>
      <div class="flows">
        <router-link :to="basePath">enkelt</router-link>
        <router-link :to="`${partnerPathPf}/default`">pensjonistforbundet</router-link>
        <router-link :to="`${partnerPathObos}/default`">obos</router-link>
      </div>
    </template>
  </div>
</template>

<script>
import config from '@/temp/config';

export default {
  name: 'Dev',
  data() {
    return {
      basePath: config.appPath.toLowerCase(),
      defaultFlow: 'enkelt/bestill',
    };
  },
  computed: {
    showMulti() {
      return this.$route.path.includes('multi');
    },
    showFamily() {
      return this.$route.path.includes('familie');
    },
    showPartnerPF() {
      return this.$route.path.includes('partner/pensjonistforbundet');
    },
    showPartnerObos() {
      return this.$route.path.includes('partner/obos');
    },
    isPartnerFlow() {
      return this.$route.path.includes('partner');
    },
    redirectPath() {
      return this.basePath.replace(this.defaultFlow, 'redirect');
    },
    multiPath() {
      return this.basePath.replace(this.defaultFlow, 'multi/bestill');
    },
    familyPath() {
      return this.basePath.replace(this.defaultFlow, 'familie/bestill');
    },
    partnerPathPf() {
      return this.basePath.replace(this.defaultFlow, 'partner/pensjonistforbundet');
    },
    partnerPathObos() {
      return this.basePath.replace(this.defaultFlow, 'partner/obos');
    },
  },
  methods: {
    setTrumfId() {
      this.$store.dispatch('app/addItem', { key: 'trumfId', value: '123' });
      console.log(`${this.$options.name}: Saved value: '123' for key: 'trumfId'`);
    },
    deleteTrumfId() {
      this.$store.dispatch('app/deleteItem', 'trumfId');
      console.log(`${this.$options.name}: Cleared value for key: 'trumfId'`);
    },
    loadDefaultStore() {
      const selectedProvider = 'BankID';
      this.$store.dispatch('app/addItem', { key: 'selectedProvider', value: selectedProvider });
      console.log(`${this.$options.name}: Saved value: '${selectedProvider}' for key: 'selectedProvider'`);

      const firstname = 'Frederick';
      this.$store.dispatch('app/addItem', { key: 'firstname', value: firstname });
      console.log(`${this.$options.name}: Saved value: '${firstname}' for key: 'firstname'`);

      const lastname = 'Ørting';
      this.$store.dispatch('app/addItem', { key: 'lastname', value: lastname });
      console.log(`${this.$options.name}: Saved value: '${lastname}' for key: 'lastname'`);

      const birthday = '02.02.1992';
      this.$store.dispatch('app/addItem', { key: 'birthday', value: birthday });
      console.log(`${this.$options.name}: Saved value: '${birthday}' for key: 'birthday'`);

      const street = 'Gentoftevej 5';
      this.$store.dispatch('app/addItem', { key: 'street', value: street });
      console.log(`${this.$options.name}: Saved value: '${street}' for key: 'street'`);

      const city = 'Lillestrøm';
      this.$store.dispatch('app/addItem', { key: 'city', value: city });
      console.log(`${this.$options.name}: Saved value: '${city}' for key: 'city'`);

      const postal = '2000';
      this.$store.dispatch('app/addItem', { key: 'postal', value: postal });
      console.log(`${this.$options.name}: Saved value: '${postal}' for key: 'postal'`);

      const email = 'test@talkmore.no';
      this.$store.dispatch('app/addItem', { key: 'email', value: email });
      console.log(`${this.$options.name}: Saved value: '${email}' for key: 'email'`);

      const phonenumber = '98123456';
      this.$store.dispatch('app/addItem', { key: 'phonenumber', value: phonenumber });
      console.log(`${this.$options.name}: Saved value: '${phonenumber}' for key: 'phonenumber'`);

      const oidcToken = '123';
      this.$store.dispatch('app/addItem', { key: 'oidcToken', value: oidcToken });
      console.log(`${this.$options.name}: Saved value: '${oidcToken}' for key: 'oidcToken'`);
    },
    loadSingleStore() {
      this.loadDefaultStore();
      location.reload();
      // add singleflow specific store values below
    },
    loadMultiStore() {
      this.loadDefaultStore();
      location.reload();
      // add multiflow specific store values below
    },
    loadFamilyStore() {
      this.loadDefaultStore();
      location.reload();
      // add familyflow specific store values below
    },
  },
};
</script>

<style lang="scss" scoped>
.dev {
  font-size: 14px;
  display: flex;
  flex-direction: column;
  margin-top: 4em;
  margin-bottom: 8em;

  a {
    cursor: pointer;
    padding: 0.5em 0 0.5em 0.5em;
  }

  .steps {
    text-align: center;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;

    > a {
      color: cadetblue;

      &:not(:last-child):after {
        content: '|';
        padding-left: 0.5em;
      }
    }
  }

  .actions {
    text-align: center;

    > a {
      color: rgb(222, 154, 36);

      &:not(:last-child):after {
        content: '-';
        padding-left: 0.5em;
      }
    }
  }

  .flows {
    text-align: center;

    > a {
      color: rgb(147, 147, 207);

      &:not(:last-child):after {
        content: '-';
        padding-left: 0.5em;
      }
    }
  }
}
</style>
