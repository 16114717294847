import Vue from 'vue';
import { nanoid } from 'nanoid';
import { getProductPriceFromRates } from '../../../../Talkmore.Web.Vue.Shared/src/utils';

const state = {};

const mutations = {
  ADD_ITEM(state, { key, value }) {
    Vue.set(state, key, value);
  },
  DELETE_ITEM(state, key) {
    Vue.delete(state, key);
  },
  appendToCurrentUser(state, { key, value }) {
    if (!state.currentItem?.user) {
      console.error('No current user found!');
      return;
    }
    Vue.set(state.currentItem.user, key, value);
  },
  appendToCurrentItem(state, { key, value }) {
    Vue.set(state.currentItem, key, value);
  },
  removeFromCurrentUser(state, key) {
    Vue.delete(state.currentItem.user, key);
  },
  removeFromCurrentItem(state, key) {
    Vue.delete(state.currentItem, key);
  },
  CLEAR_CURRENT_ITEM(state) {
    state.currentItem = {};
  },
  UPDATE_PRODUCT(state, { index, product, priceKey }) {
    Vue.set(state.items, index, { ...state.items[index], product, priceKey });
  },
  UPDATE_EXTRA_SIM(state, { index, extraSim }) {
    Vue.set(state.items, index, { ...state.items[index], extraSim });
  },
  UPDATE_ITEM_PRICE(state, { index, price }) {
    Vue.set(state.items, index, { ...state.items[index], price });
  },
  UPDATE_BASKET_ITEM(state, { index, newItem }) {
    Vue.set(state.items, index, newItem);
  },
  CLEAR_BASKET_ITEMS(state) {
    state.items = [];
  },
  CLEAR_OWNER_DATA(state) {
    state.items.forEach((item, index) => {
      Vue.delete(state.items[index], 'isCompleted');
      if (item.user?.info === 'owner') {
        Vue.delete(state.items[index], 'user');
      }
    });
  },
};

const actions = {
  addItem({ commit }, { key, value }) {
    commit('ADD_ITEM', { key, value });
  },
  deleteItem({ commit }, key) {
    commit('DELETE_ITEM', key);
  },
  addCurrentItem({ commit }, item) {
    commit('ADD_ITEM', { key: 'currentItem', value: item });
  },
  appendToCurrentUser({ commit }, data) {
    commit('appendToCurrentUser', data);
  },
  appendToCurrentItem({ commit }, data) {
    commit('appendToCurrentItem', data);
  },
  removeFromCurrentUser({ commit }, key) {
    commit('removeFromCurrentUser', key);
  },
  clearCurrentItem({ commit }) {
    commit('CLEAR_CURRENT_ITEM');
  },
  removeFromCurrentItem({ commit }, key) {
    commit('removeFromCurrentItem', key);
  },
  addBasketItem({ commit, getters }, basketItem) {
    let items = getters.getItems;
    commit('DELETE_ITEM', 'items');
    let basketItemCopy = { ...basketItem };
    basketItemCopy.id = nanoid();
    items.push(basketItemCopy);
    commit('ADD_ITEM', { key: 'items', value: items });
  },
  removeBasketItemWithPriceKey({ commit, getters }, basketItem) {
    let items = getters.getItems;
    // first try to find index of item that has same id and price key and no user-property
    let existingItemIndex = items.findIndex(
      (f) => f.product.id === basketItem.product.id && f.priceKey === basketItem.priceKey && !f.user,
    );
    if (existingItemIndex < 0) {
      // find index of item that has same id and price key
      existingItemIndex = items.findIndex((f) => f.product.id === basketItem.product.id && f.priceKey === basketItem.priceKey);
    }
    if (existingItemIndex >= 0) {
      commit('DELETE_ITEM', 'items');
      items.splice(existingItemIndex, 1);

      commit('ADD_ITEM', { key: 'items', value: items });
    }
  },
  removeBasketItem({ commit, getters }, basketItem) {
    let items = getters.getItems;

    const existingItemIndex = items.findIndex((f) => f.id === basketItem.id);

    if (existingItemIndex >= 0) {
      commit('DELETE_ITEM', 'items');
      items.splice(existingItemIndex, 1);

      commit('ADD_ITEM', { key: 'items', value: items });
    }
  },
  updateBasketItemProduct({ commit }, { index, product, priceKey }) {
    if (index >= 0) {
      commit('UPDATE_PRODUCT', { index, product, priceKey });
    }
  },
  updateBasketItem({ commit, getters }, { id, newItem }) {
    const index = getters.getItems.findIndex((f) => f.id === id);
    if (index >= 0) commit('UPDATE_BASKET_ITEM', { index, newItem });
  },
  updateBasketItemExtraSim({ commit, state }, { id, extraSim }) {
    const index = state.items.findIndex((f) => f.id === id);
    if (index >= 0) commit('UPDATE_EXTRA_SIM', { index, extraSim });
  },
  clearState({ commit, state }) {
    // eslint-disable-next-line no-unused-vars
    Object.entries(state).forEach(([key, value]) => commit('DELETE_ITEM', key));

    console.log('Store: Cleared whole basket state!');
  },
};

const getters = {
  getCurrentItem(state) {
    return state.currentItem ?? {};
  },
  getItems(state) {
    return state.items ?? [];
  },
  hasOwnerSelected(state) {
    if (!state.items) return false;
    return state.items.some((item) => item.user && item.user.info === 'owner');
  },
  getUsersCount(state) {
    return state.usersCount ?? null;
  },
  allUsersCompleted(state) {
    if (!state.items || state.items.length === 0) return false;
    return state.items.every((item) => item.isCompleted);
  },
  getItemsCountByProductAndPriceKey: (state) => (product, priceKey) => {
    if (!state.items) return 0;
    return state.items.filter((item) => item.product.id === product.id && item.priceKey === priceKey).length;
  },
  getCurrentUserSimDeliveryType(state) {
    return state.currentItem?.user?.simCardDeliveryType ?? null;
  },
  getBasket: (state, getters, rootState, rootGetters) => {
    const basket = {
      items: null,
      total: 0,
      totalFull: 0,
    };

    if (state.items) {
      const items = [];
      const familyCrmItemPrice = rootState.config.familyCrmItem?.price;
      const familyCrmItemPriceDiscount = rootState.config.familyCrmItem?.priceDiscount;
      const ratePlansDefault = rootGetters['config/getRatePlansDefault'];
      let priceFull;

      state.items.forEach((item) => {
        const ratePlans = rootGetters['config/getRatePlansByPriceKey'](item.priceKey);
        const product = item.product;
        const price = getProductPriceFromRates({ product, ratePlans });
        priceFull = getProductPriceFromRates({ product, ratePlans: ratePlansDefault });
        const newItem = { ...item };
        newItem.price = price;
        newItem.priceFull = priceFull;
        newItem.coupon = rootGetters['config/getCoupon'](item.priceKey);
        items.push(newItem);
        // in family flow, the total is calculated using familyCrmItemPrice instead of the price of the family subscription
        if (familyCrmItemPrice) {
          basket.total += familyCrmItemPriceDiscount ?? familyCrmItemPrice;
          basket.totalFull += familyCrmItemPrice;
        } else {
          basket.total += price;
          basket.totalFull += priceFull;
        }
      });

      basket.items = items;
      // in family flow, the family subscription is added to the total
      if (familyCrmItemPrice && items.length) {
        basket.total += items[0].price;
        basket.totalFull += priceFull;
      }
    }

    if (rootState.app?.digitalSecurity) {
      const price = rootState.config.digitalSecurity.crmItem?.price;
      basket.total += price;
      basket.totalFull += price;
    }

    return basket;
  },
  getBasketState: (state, getters, rootState) => {
    if (!state.items) return null;

    const basketState = {
      OidcToken: rootState.app?.oidcToken,
      ConfigId: rootState.app?.configId,
      DocumentCookie: document.cookie,
      Owner: {
        OwnerPhoneNumber: rootState.app?.phonenumber,
        FirstName: rootState.app?.firstname,
        LastName: rootState.app?.lastname,
        BirthDate: rootState.app?.birthday,
        Email: rootState.app?.email,
        Street: rootState.app?.street,
        ZipCode: rootState.app?.postal,
        City: rootState.app?.city,
        ReceiveOffers: {
          Sms: rootState.app?.consentSms ? true : false,
          Email: rootState.app?.consentEmail ? true : false,
        },
      },
      Rewards: {
        RecruitmentCode: rootState.app?.recruitmentCode,
        PartnerCode: rootState.config?.discount?.discountName,
        PartnerMemberId: rootState.app?.partnerCode,
        TrumfMemberId: rootState.app?.trumfId,
        // PartnerCampaignId will be mapped via PartnerCode on backend side (script from Eivind)
        PartnerCampaignId: null,
      },
      UserOrders: [],
      HasDigitalSafety: rootState.app?.digitalSecurity === true ? true : false,
      ...(state.usersCount && { RatePlanId: state.items[0].product.fields['CRM Item']?.value }),
    };

    // add startDate if familyflow
    if (state.usersCount) basketState.StartDate = rootState.app?.startDate;

    // add userorders per basket item
    state.items.forEach((item) => {
      if (!item.user) return;
      const userOrder = {
        FirstName: item.user?.firstname,
        LastName: item.user?.lastname,
        BirthDate: item.user?.birthday,
        IsOwner: item.user.info === 'owner' ? true : false,
        ...(!state.usersCount && { RatePlanId: item.product.fields['CRM Item']?.value }),
        Sims: {
          NumberOfExtraSims: item.extraSim ?? 0,
          SimCardDeliveryType: item.user?.simType === 'esim' ? '2' : item.user?.simCardDeliveryType,
        },
        ProductOptionIds: [],
      };

      if (item.user.email) userOrder.Email = item.user.email;
      if (item.user.fullControl) userOrder.ProductOptionIds.push('202211231421521349'); // TODO: do not hardcode crm id
      if (item.user.childSecurity) userOrder.ProductOptionIds.push('202010290645050062'); // TODO: do not hardcode crm id
      if (userOrder.ProductOptionIds.length === 0) userOrder.ProductOptionIds = null;
      if (item.user.porting === 'keep-number') {
        userOrder.Porting = {
          PhoneNumber: item.user.phonenumber,
          // in single flow startDate is in app store while for multi flow its per user
          // for family flow its also in app store but we do not want to collect it on userOrder
          PortingDate: rootState.app?.startDate ? rootState.app?.startDate : item.user.startDate,
        };
      }

      basketState.UserOrders.push(userOrder);
    });

    return basketState;
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};
