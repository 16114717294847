<template>
  <transition :name="animate ? 'slide' : null">
    <div v-if="visible" class="age-info-box desktop-narrow" :class="bgClass">
      <ScImage :media="isU30 ? fields.AgeInfoBoxIconUnder30 : fields.AgeInfoBoxIconOver30" class="age-info-box__icon" />
      <ScText tag="p" :field="isU30 ? fields.AgeInfoBoxTextUnder30 : fields.AgeInfoBoxTextOver30" class="text-s" />
    </div>
  </transition>
</template>

<script>
// import { mapGetters } from 'vuex';
import { Text, Image } from '@sitecore-jss/sitecore-jss-vue';
import { calculateAge } from '../../../Talkmore.Web.Vue.Shared/src/utils';

export default {
  name: 'AgeInfoBox',
  components: {
    ScText: Text,
    ScImage: Image,
  },
  props: {
    fields: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      visible: false,
      isU30: null,
      animate: false,
      ownerBirthday: this.$store.state.app?.birthday ?? null,
      age: null,
    };
  },
  computed: {
    // Todo: fix getters not being reactive when changing values inside array
    // after this is fixed we can again use this getter
    // ...mapGetters({
    //   currentItem: 'basket/getCurrentItem',
    // }),
    bgClass() {
      return this.isU30
        ? this.fields.AgeInfoBoxBackgroundColorUnder30?.fields.Type.value
        : this.fields.AgeInfoBoxBackgroundColorOver30?.fields.Type.value;
    },
    userBirthday() {
      return this.$store.state.basket?.currentItem?.user?.birthday;
    },
    event() {
      // use value for radio buttons (as it is unique), and key for input fields (as they don't have a value field)
      return this.fields.ToggleByComponentValue?.fields.Value?.value ?? this.fields.ToggleByComponentValue?.fields.Key?.value;
    },
    priceKey() {
      return this.$store.state.basket?.currentItem?.priceKey;
    },
    hasNormalProductAndIsUnder30() {
      if (!this.age || !this.priceKey) return null;
      return this.age > 0 && this.age < 30 && this.priceKey === 'Normal';
    },
    hasUnder30ProductAndIsOver30() {
      if (!this.age || !this.priceKey) return null;
      return this.age >= 30 && this.priceKey === 'U30';
    },
  },
  watch: {
    userBirthday() {
      this.toggle();
    },
  },
  mounted() {
    this.$root.$on('changePrice', () => {
      if (this.visible && this.age) {
        this.changePrice();
      }
    });
    if (this.fields.ToggleByComponentValue) {
      this.$root.$on(this.event, (data) => {
        this.animate = true;
        // toggled on radio button
        if (data === 'true' && this.ownerBirthday) this.toggle(this.ownerBirthday);
        // toggled off radio button
        else if (data === 'false') this.visible = false;
        else this.toggle(data); // data should be a date string (DD.MM.YYYY) in this case
      });
    }
  },
  beforeDestroy() {
    if (this.fields.ToggleByComponentValue) this.$root.$off(this.event);
    this.$root.$off('changePrice');
  },
  methods: {
    toggle(userBirthday = this.userBirthday) {
      if (!this.priceKey || !userBirthday) return;

      this.age = calculateAge(userBirthday);

      if (this.hasNormalProductAndIsUnder30 === true) {
        this.visible = true;
        this.isU30 = true;
      } else if (this.hasUnder30ProductAndIsOver30 === true) {
        this.visible = true;
        this.isU30 = false;
      } else {
        this.visible = false;
        this.isU30 = null;
      }
    },
    changePrice() {
      let value = 'Normal';
      if (this.hasNormalProductAndIsUnder30) {
        value = 'U30';
      }
      this.$store.dispatch('basket/appendToCurrentItem', { key: 'priceKey', value });
      this.$store.dispatch('config/updatePriceKey', value);
    },
  },
};
</script>

<style lang="scss" scoped>
.slide-enter-active {
  @include transition(0.3s);
  overflow: hidden;
}
.slide-enter-to {
  max-height: 200px;
  opacity: 1;
}
.slide-enter {
  max-height: 0;
  opacity: 0;
}

.age-info-box {
  border-radius: 16px;
  display: flex;
  align-items: center;
  padding: $spacing-s;
  margin-bottom: $spacing-s;
  gap: 12px;

  img {
    width: 39px;
    height: 36px;
  }
}
</style>
