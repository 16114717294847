<template>
  <div>
    <div class="product-list desktop-wide">
      <div v-for="(productList, i) in fields.ProductLists" :key="i">
        <ScText
          tag="h2"
          class="product-list__title m-t-m m-md-t--clear text-m text-md-m"
          :field="productList.fields.ProductListTitle"
        />

        <div class="product-list__product-list">
          <div
            tabindex="0"
            @click="selectProduct(product)"
            @keydown.enter.space.prevent="selectProduct(product)"
            class="product-tile btn"
            v-for="product in productList.fields.ProductListProducts"
            :key="product.id"
            :class="[
              {
                selected: isSelected(product),
                newselected: selectedProduct == product && !isSelected(product),
                discount: !isNormalPrice,
              },
            ]"
          >
            <div class="pill text-xs" v-if="selectedProduct == product || isSelected(product)">{{ getPillText(product) }}</div>
            <div>
              <h4 class="product-tile__title text-l">{{ product.fields.Title.value }}</h4>
              <ScText tag="span" class="product-tile__descrition text-s" :field="product.fields.ProductDescription" />
            </div>
            <div class="product-tile__info">
              <div class="product-tile__info-texts">
                <div class="product-tile__info-price">
                  <strong class="title-xxs">{{ getProductPriceText({ product }) }}</strong>
                  <span class="postfix text-xs">{{ $t('price-postfix') }}</span>
                </div>
                <s v-if="!isNormalPrice" class="text-xs"
                  >{{ $t('before') }} {{ getProductPriceText({ product, getNormalPrice: true }) }}{{ $t('price-postfix') }}</s
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <ButtonWithBack :fields="fields"></ButtonWithBack>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { Text } from '@sitecore-jss/sitecore-jss-vue';
import ButtonWithBack from './ButtonWithBack.vue';
import { gtmSharedParams, gtmSharedEcommerceParams, trackItemList, getGtmItem } from '@/gtmTracking';
import { gtmEcommerceEvent } from '../../../Talkmore.Web.Vue.Shared/src/utils/gtmTracking';
import { getProductPriceFromRates, getProductIndex } from '../../../Talkmore.Web.Vue.Shared/src/utils';

export default {
  name: 'ProductList',
  components: {
    ScText: Text,
    ButtonWithBack,
  },
  inject: {
    onSaveEvent: {
      type: Function,
    },
  },
  props: {
    fields: {
      type: Object,
    },
  },
  computed: {
    ...mapGetters({
      getRatePlans: 'config/getRatePlans',
      getRatePlansDefault: 'config/getRatePlansDefault',
      basket: 'basket/getBasket',
      usersCount: 'basket/getUsersCount',
      priceKeyNormal: 'config/getPriceKeyNormal',
      getRatePlansByPriceKey: 'config/getRatePlansByPriceKey',
    }),
    basketItems() {
      return this.basket.items;
    },
    selectedProduct() {
      if (!this.$store.state.app?.productListSelectedProduct) return false;
      return this.$store.state.app?.productListSelectedProduct;
    },
    allProducts() {
      return this.$store.state.app?.allProducts;
    },
    isNormalPrice() {
      const currentItemPriceKey = this.currentItem?.priceKey;
      if (!currentItemPriceKey) return this.$store.state.config?.useDefaultPrices;
      else return currentItemPriceKey === this.priceKeyNormal;
    },
    currentItem() {
      return this.$store.state.basket?.currentItem;
    },
  },
  beforeDestroy() {
    this.$root.$off('onSave');
    this.$root.$off('onSaveProductMulti');
  },
  created() {
    // If there is only one item in the basket or there are multiple users, make the first item current item so that it is highlighted
    if (this.basketItems?.length === 1 || (this.usersCount && this.basketItems?.length > 0)) {
      this.$store.dispatch('basket/addCurrentItem', this.basketItems[0]);
    }
  },
  mounted() {
    if (this.allProducts && this.allProducts.length > 0) trackItemList(this.$el, true);

    this.$root.$on('onSave', () => {
      const index = getProductIndex(this.allProducts, this.selectedProduct);
      if (this.usersCount) {
        // familyflow
        if (this.basketItems?.length !== 0) {
          this.$root.$emit('onUpdateBasketItemProduct', {
            basketItem: this.basketItems[0],
            product: this.selectedProduct,
            index,
          });
        } else {
          // this case should never be hit in theory because we always have at least one item
          this.$root.$emit('onSaveMultipleProducts', this.selectedProduct, index, this.usersCount);
        }
      } else {
        // singleflow
        this.$root.$emit('onSaveSingleProduct', this.selectedProduct, index);
      }
    });

    this.$root.$on('onSaveProductMulti', () => {
      // multiflow
      if (this.currentItem && this.selectedProduct)
        this.$root.$emit('onUpdateBasketItemProduct', {
          basketItem: this.currentItem,
          product: this.selectedProduct,
          index: getProductIndex(this.allProducts, this.selectedProduct),
        });
      else console.error('No current item or selected product');
    });

    if (this.basketItems?.length) {
      this.basketItems.forEach((item) => {
        if (this.isSelected(item.product)) this.onSaveEvent('productListSelectedProduct', item.product, this.$options.name);
      });
    }
  },
  methods: {
    isSelected(product) {
      if (!this.basketItems?.length) return false;
      return product.id === this.$store.state.basket.currentItem?.product?.id;
    },
    selectProduct(product) {
      this.onSaveEvent('productListSelectedProduct', product, this.$options.name);
      const coupon = this.currentItem?.coupon;
      const ratePlans = this.getRatePlansByPriceKey(this.currentItem.priceKey);
      const price = getProductPriceFromRates({ product, ratePlans });
      const items = [getGtmItem({ product, price, coupon })];
      gtmEcommerceEvent({
        event: 'select_item',
        items,
        gtmSharedParams: gtmSharedParams(),
        gtmSharedEcommerceParams: gtmSharedEcommerceParams(),
      });
    },
    getProductPriceText({ product, getNormalPrice = false }) {
      const ratePlans = getNormalPrice
        ? this.getRatePlansByPriceKey(this.priceKeyNormal)
        : this.getRatePlansByPriceKey(this.currentItem?.priceKey);
      if (ratePlans.length === 0) return '';
      const familyCrmItem = this.$store.state.config?.familyCrmItem;
      const familyCrmItemPrice =
        familyCrmItem?.priceDiscount && !getNormalPrice ? familyCrmItem?.priceDiscount : familyCrmItem?.price;
      const price = getProductPriceFromRates({ product, ratePlans, multiplyBy: this.usersCount, familyCrmItemPrice });
      return price + this.$t('price-denote');
    },
    getPillText(product) {
      return this.isSelected(product) ? this.$t('selected') : this.$t('switch-to');
    },
  },
};
</script>

<style lang="scss" scoped>
.product-list {
  margin-bottom: 32px;
  display: flex;
  flex-direction: column;
  gap: 24px 0;

  &__title {
    text-align: left;

    @include desktop {
      text-align: center;
    }
  }

  &__product-list {
    margin-top: 24px;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 16px;
  }

  .product-tile {
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 8px;
    padding: 12px 16px;
    background-color: #{$color-white};
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
    border: 1px solid #e5e5e5;
    border-radius: 16px;
    @include transition(0.3s);

    .pill {
      position: absolute;
      top: -13px;
      right: 16px;
      line-height: normal;
      height: 26px;
      min-height: 26px;
      @include desktop {
        min-height: 30px;
        top: -15px;
      }
    }

    &.selected {
      border-radius: 16px;
      border: 1px solid var(--Green-Tint, #9adf77);
      background: var(--White, #fff);
      box-shadow: inset 0px 0px 0px 1px #9adf77;
    }

    &.newselected {
      border-radius: 16px;
      border: 1px solid var(--Green-Tint-light, #bceaa4);
      box-shadow: inset 0px 0px 0px 1px #bceaa4;
      background: var(--Green-Lighter, #effae9);
    }

    &__title {
      display: flex;
      flex: 1;
      align-items: center;
      font-size: 19.5px;
    }

    &__info {
      display: flex;
      align-items: center;
      gap: 16px;

      &-texts {
        display: flex;
        flex-direction: column;
        align-items: flex-start;

        strong {
          font-size: 22px;
        }

        .postfix {
          padding-left: 4px;
        }
      }
    }

    &.discount {
      .product-tile__info-price strong {
        color: $color-green-darker;
      }
    }
  }
}
</style>
