import { dataFetcher } from '../dataFetcher';
import { baseUrl } from '@/baseUrl';
import { apiHost } from '@/apiHost';
import store from '../store';

const endpoints = {
  rateplans: 'tmapi/orderflow/rateplans',
  productOptions: 'tmapi/orderflow/productoptions',
  zipcode: 'tmapi/Utility/ZipCodes',
  register: 'tmapi/orderflow/privateplaceorder',
  registerFamily: 'tmapi/orderflow/familyplaceorder',
  userRegistrations: `${apiHost}/UserRegistrations/api/v2`,
};

export function getPortingDates(eSim = false) {
  const endpoint = `${endpoints.userRegistrations}/${eSim ? 'Esim/' : ''}PortingDates`;
  return dataFetcher(endpoint);
}

export function fetchRateplanPrices(body) {
  const endpoint = `${baseUrl}/${endpoints.rateplans}`;
  return dataFetcher(endpoint, body);
}

export function fetchProductOptions(body) {
  const endpoint = `${baseUrl}/${endpoints.productOptions}`;
  return dataFetcher(endpoint, body);
}

export function getCityFromZipcode(zipcode) {
  const endpoint = `${baseUrl}/${endpoints.zipcode}/${zipcode}`;
  return dataFetcher(endpoint);
}

export function validateRecruitmentCode(code) {
  const endpoint = `${endpoints.userRegistrations}/Recruitments/Validate/${code}`;
  return dataFetcher(endpoint);
}

export function registerOrder(body) {
  const endpoint = `${baseUrl}/${store.getters['basket/getUsersCount'] ? endpoints.registerFamily : endpoints.register}`;
  return dataFetcher(endpoint, body);
}
