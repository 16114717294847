<template>
  <div v-if="showDiscountField" class="membership m-t-s m-md-t-m p-t-s p-md-t-m">
    <div
      :class="{ 'validate-error': showCodeError, 'validate-success': partnerCodeActivated }"
      class="form-field form-field--split form-field--small"
    >
      <div class="form-field__description">
        <div class="form-field__description-head">
          <label for="membership" class="text-s text-sm-s text-md-s bold" v-if="label">{{ label }}</label>
        </div>
        <span class="form-field__description-text text-s text-sm-s text-md-s" v-if="labelDescription">{{
          labelDescription
        }}</span>
      </div>
      <div class="form-field__input input-field--small">
        <input
          inputmode="numeric"
          autocapitalize="none"
          autocorrect="off"
          id="membership"
          name="membership"
          placeholder=""
          type="text"
          :maxlength="fieldLength"
          v-model="code"
          @keydown.enter.prevent="addCode()"
          @change="onChange()"
        />
        <button type="button" class="button-textlink button-small button-hover" v-if="!partnerCodeActivated" @click="addCode">
          <span class="main">
            <span class="text-normal text-s text-md-m">{{ $t('activate') }}</span>
          </span>
        </button>
        <button type="button" class="button-textlink button-small button-hover" v-else @click="removeCode">
          <span class="main">
            <span class="text-normal text-s text-md-m">{{ $t('remove') }}</span>
          </span>
        </button>
      </div>
      <div class="form-field__feedback-container">
        <span class="text-xs text-md-xs validation-text" v-if="partnerCodeActivated">{{ $t('code-activated') }}</span>
        <span class="text-xs text-md-xs validation-text p-t--clear" v-if="showCodeError">{{ validationErrorText }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import { validateChecksum } from '@/partnerValidators';
import { gtmSharedParams } from '@/gtmTracking';
import { gtmAddCouponEvent } from '../../../../Talkmore.Web.Vue.Shared/src/utils/gtmTracking';

export default {
  name: 'Membership',
  data() {
    return {
      showCodeError: false,
      code: this.$store.state.app.partnerCode,
      hasDiscount: this.$store.state.config?.discount != null,
      fieldLength: this.$store.state.config?.discount?.discountFieldLength,
      label: this.$store.state.config?.discount?.discountFieldText,
      labelDescription: this.$store.state.config?.discount?.discountFieldDescription,
      validationErrorText: this.$store.state.config?.discount?.discountValidationText,
      regexFieldValue: this.$store.state.config?.discount?.discountFieldRegex,
      discountName: this.$store.state.config?.discount?.discountName,
      hasChecksumValidation: this.$store.state.config?.discount?.discountChecksumValidation,
    };
  },
  computed: {
    showDiscountField() {
      return this.hasDiscount && this.regexFieldValue;
    },
    partnerCodeActivated() {
      return this.$store.state.app.partnerCode != null;
    },
  },
  watch: {
    showCodeError(newVal) {
      if (newVal === true) this.$root.$emit('onErrorMessage', this.validationErrorText);
    },
    code() {
      this.showCodeError = false;
    },
  },
  methods: {
    addCode() {
      if (!this.code) {
        this.showCodeError = false;
        return;
      }

      const isChecksumValid = this.hasChecksumValidation ? validateChecksum(this.discountName, this.code) : true;

      if (this.isRegexValid(this.code) && isChecksumValid) {
        this.showCodeError = false;
        this.savePartnerCode(this.code);
        gtmAddCouponEvent({ coupon: this.code, gtmSharedParams: gtmSharedParams() });
      } else {
        this.showCodeError = true;
        this.$store.dispatch('app/deleteItem', 'partnerCode');
      }
    },
    isRegexValid(code) {
      const reg = this.regexFieldValue;
      let regex;

      // Check if reg looks like a regex pattern (starts and ends with '/')
      if (reg.startsWith('/') && reg.lastIndexOf('/') > 0) {
        // Extract the pattern and flags
        const pattern = reg.slice(1, reg.lastIndexOf('/')); // Between the first and last '/'
        const flags = reg.slice(reg.lastIndexOf('/') + 1); // After the last '/'
        regex = new RegExp(pattern, flags);
      } else {
        // Treat as plain text, escaping any special regex characters
        const escapedPattern = reg.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
        regex = new RegExp(`^${escapedPattern}$`);
      }

      return regex.test(code);
    },
    savePartnerCode(code) {
      this.$store.dispatch('app/addItem', { key: 'partnerCode', value: code });
      console.log(`${this.$options.name}: Saved value: '${code}' for key: 'partnerCode'`);
    },
    removeCode() {
      this.code = null;
      this.showCodeError = false;
      this.$store.dispatch('app/deleteItem', 'partnerCode');
      console.log(`${this.$options.name}: Cleared value for key: 'partnerCode'`);
    },
    onChange() {
      this.showCodeError = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.membership {
  border-top: 1px solid $color-grey-tint;

  .form-field__feedback-container {
    padding: $spacing-xxs 0 0;
  }
}
</style>
