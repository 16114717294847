<template>
  <div class="oidc-redirect">
    <RouteLoading :fields="fields" />
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import RouteLoading from './app/RouteLoading.vue';
import { gtmSharedParams } from '@/gtmTracking';
import { gtmEproviderCallbackEvent } from '../../../Talkmore.Web.Vue.Shared/src/utils/gtmTracking';

export default {
  name: 'OidcRedirect',
  components: {
    RouteLoading,
  },
  props: {
    fields: {
      type: Object,
    },
  },
  computed: {
    ...mapGetters({
      oidcUser: 'oidcStore/oidcUser',
      oidcIdToken: 'oidcStore/oidcIdToken',
      oidcPartnerUser: 'partnerOidcStore/oidcUser',
    }),
  },
  created() {
    const providers = this.$store.state.app.selectedProvider; // get selected provider for gtm event

    // check if we came from a trumf login
    const isTrumfLogin = localStorage.getItem('TrumfLogin');

    if (isTrumfLogin) {
      this.partnerOidcSignInCallback()
        .then(() => {
          const trumfId = this.oidcPartnerUser['http://id.trumf.no/claims/medlem'];

          // save trumf id
          this.$store.dispatch('app/addItem', { key: 'trumfId', value: trumfId });
          console.log(`${this.$options.name}: Saved value: '${trumfId}' for key: 'trumfId'`);

          // TODO: clarify what we need to track for trumf login success
          // gtmEproviderCallbackEvent({ providers, isSuccess: true, gtmSharedParams: gtmSharedParams() });

          // we are done logging in, redirect to next step
          const redirectPage = localStorage.getItem('RedirectPage');
          this.$router.push(redirectPage);
        })
        .catch((err) => {
          console.log('partnerOidcSignInCallback: OOPSIE WOOPSIE', err);
          // TODO: clarify what we need to track for trumf login error
          // gtmEproviderCallbackEvent({ providers, isSuccess: false, gtmSharedParams: gtmSharedParams() });
        })
        .finally(() => {
          localStorage.removeItem('TrumfLogin');
          console.log(`${this.$options.name}: Removed value: 'TrumfLogin' from local storage`);
        });
    } else {
      this.oidcSignInCallback()
        .then(() => {
          // save all user info we need
          this.$store.dispatch('app/addItem', { key: 'oidcToken', value: this.oidcIdToken });
          console.log(`${this.$options.name}: Saved value: '${this.oidcIdToken}' for key: 'oidcToken'`);

          this.$store.dispatch('app/addItem', { key: 'firstname', value: this.oidcUser.givenname });
          console.log(`${this.$options.name}: Saved value: '${this.oidcUser.givenname}' for key: 'firstname'`);

          this.$store.dispatch('app/addItem', { key: 'lastname', value: this.oidcUser.surname });
          console.log(`${this.$options.name}: Saved value: '${this.oidcUser.surname}' for key: 'lastname'`);

          const birthdate = new Date(this.oidcUser.birthdate).toJSON().slice(0, 10).split('-').reverse().join('.');
          this.$store.dispatch('app/addItem', { key: 'birthday', value: birthdate });
          console.log(`${this.$options.name}: Saved value: '${birthdate}' for key: 'birthday'`);

          this.$store.dispatch('app/addItem', { key: 'email', value: this.oidcUser.email });
          console.log(`${this.$options.name}: Saved value: '${this.oidcUser.email}' for key: 'email'`);

          // there are two different properties that could contain the street and postal values
          if (this.oidcUser.address?.length || this.oidcUser.streetaddress?.length) {
            // we take the first street and postal we can find
            if (this.oidcUser.address?.length) {
              const postal = this.oidcUser.address[0]?.['postal_code'];
              const street = this.oidcUser.address[0]?.['street_address'];

              if (postal) {
                this.$store.dispatch('app/addItem', { key: 'postal', value: postal });
                console.log(`${this.$options.name}: Saved value: '${postal}' for key: 'postal'`);
              }

              if (street) {
                this.$store.dispatch('app/addItem', { key: 'street', value: street });
                console.log(`${this.$options.name}: Saved value: '${street}' for key: 'street'`);
              }
            } else if (this.oidcUser.streetaddress?.length) {
              const postal = this.oidcUser.streetaddress[0]?.['postal_code'];
              const street = this.oidcUser.streetaddress[0]?.['street_address'];

              if (postal) {
                this.$store.dispatch('app/addItem', { key: 'postal', value: postal });
                console.log(`${this.$options.name}: Saved value: '${postal}' for key: 'postal'`);
              }

              if (street) {
                this.$store.dispatch('app/addItem', { key: 'street', value: street });
                console.log(`${this.$options.name}: Saved value: '${street}' for key: 'street'`);
              }
            }
          }

          // there is no property for phone number without country code, so we have to remove it on our own
          if (this.oidcUser.phone_number) {
            let phoneNumber = this.oidcUser.phone_number.toString();

            if (phoneNumber.length === 10 && phoneNumber.startsWith('47')) phoneNumber = phoneNumber.substring(2);
            else if (phoneNumber.length === 11 && phoneNumber.startsWith('+47')) phoneNumber = phoneNumber.substring(3);
            else if (phoneNumber.length === 12 && phoneNumber.startsWith('0047')) phoneNumber = phoneNumber.substring(4);

            this.$store.dispatch('app/addItem', { key: 'phonenumber', value: phoneNumber });
            console.log(`${this.$options.name}: Saved value: '${phoneNumber}' for key: 'phonenumber'`);
          }
          gtmEproviderCallbackEvent({ providers, isSuccess: true, gtmSharedParams: gtmSharedParams() });

          // we are done logging in, redirect to next step
          const redirectPage = localStorage.getItem('RedirectPage');
          this.$router.push(redirectPage);
        })
        .catch((err) => {
          console.log('oidcSignInCallback: OOPSIE WOOPSIE', err);
          gtmEproviderCallbackEvent({ providers, isSuccess: false, gtmSharedParams: gtmSharedParams() });
        });
    }
  },
  mounted() {
    window.addEventListener('vuexoidc:userLoaded', this.userLoaded);
    window.addEventListener('vuexoidc:oidcError', this.oidcError);
    window.addEventListener('vuexoidc:automaticSilentRenewError', this.automaticSilentRenewError);
  },
  destroyed() {
    window.removeEventListener('vuexoidc:userLoaded', this.userLoaded);
    window.removeEventListener('vuexoidc:oidcError', this.oidcError);
    window.removeEventListener('vuexoidc:automaticSilentRenewError', this.automaticSilentRenewError);
  },
  methods: {
    ...mapActions({
      oidcSignInCallback: 'oidcStore/oidcSignInCallback',
      partnerOidcSignInCallback: 'partnerOidcStore/oidcSignInCallback',
    }),
    userLoaded(e) {
      console.log(`${this.$options.name}: User loaded in vuex-oidc`, e.detail);
    },
    oidcError(e) {
      console.log(`${this.$options.name}: oidcError`, e.detail);

      // ignore no state error
      if (e.detail.error === 'No state in response') return;

      // ignore other state error
      if (e.detail.error === 'No matching state found in storage') return;

      localStorage.setItem('ShowErrorPage', true);
      console.log(`${this.$options.name}: Saved value: 'true' for key 'ShowErrorPage' in localStorage`);

      // something went wrong, lets redirect the user back to where he came from
      const errorPage = localStorage.getItem('ErrorPage');
      this.$router.push(errorPage);
    },
    automaticSilentRenewError(e) {
      console.log(`${this.$options.name}: automaticSilentRenewError in vuex-oidc`, e.detail);
    },
  },
};
</script>

<style lang="scss" scoped>
.route-loading {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background: #{$color-white}; // Should be same as background in AppRoot.vue
  padding-top: 140px;
  padding-bottom: 140px;

  &__title {
    margin-top: 54px;
  }
}
</style>
