<template>
  <div class="mentos">
    <img class="mentos__image" alt="" :src="imgSrc" />
  </div>
</template>

<script>
export default {
  name: 'Mentos',
  props: {
    imgSrc: {
      type: String,
      default: null,
    },
  },
};
</script>

<style lang="scss">
.mentos {
  position: fixed;
  bottom: 0;
  right: 0;
  z-index: -1;

  @media screen and (max-width: 992px) {
    display: none;
  }

  &__image {
    height: 315px;
    width: 202px;
  }
}
</style>
