<template>
  <div class="content-container">
    <footer :class="['footer text-xs text-md-xs', bgColor, { 'has-sticky-basket': isStickyBasketVisible }]">
      <div class="footer__left">
        <slot name="logo"></slot>
      </div>
      <div class="footer__right">
        <p v-if="showCopyrightText">{{ year }} {{ copyrightText }}</p>
        <a
          v-if="showLink"
          tabindex="0"
          role="button"
          @click="renewCookieConsent"
          @keydown.enter.space.prevent="renewCookieConsent"
          class="link"
          >{{ cookieButtonText }}</a
        >
      </div>
    </footer>
  </div>
</template>

<script>
export default {
  name: 'Footer',
  props: {
    bgColor: {
      type: String,
      default: 'bg-white',
    },
    copyrightText: {
      type: String,
      default: null,
    },
    cookieButtonText: {
      type: String,
      default: null,
    },
  },
  computed: {
    year() {
      return new Date().getFullYear();
    },
    showLink() {
      return this.cookieButtonText;
    },
    showCopyrightText() {
      return this.copyrightText;
    },
    isStickyBasketVisible() {
      return this.$store.state.app.isStickyBasketVisible;
    },
  },
  methods: {
    renewCookieConsent() {
      // eslint-disable-next-line no-undef
      if (typeof CookieConsent !== 'undefined' && CookieConsent.renew) {
        CookieConsent.renew(); // eslint-disable-line no-undef
      } else {
        console.error('CookieConsent is not defined');
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.content-container {
  margin-top: auto; // push footer to the bottom of the page
}
.footer {
  margin-top: $spacing-5xl;
  display: flex;
  flex-direction: column;

  &.has-sticky-basket {
    margin-bottom: 120px; // TODO: calculate sticky basket height and use instead
  }

  @include screen-tablet-portrait-up {
    flex-direction: row;
    justify-content: space-between;
    column-gap: $spacing-5xl;
  }

  &__right {
    display: flex;
    flex-direction: column;
    row-gap: $spacing-m;
    align-items: center;
    text-align: center;

    @include screen-tablet-portrait-up {
      row-gap: $spacing-xxs;
      align-items: flex-start;
      text-align: left;
      justify-content: center;
    }
  }
  .link {
    padding: 0;
    font-family: $font-bold;
  }
}
</style>
