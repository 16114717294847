<template>
  <div v-if="visible" class="subscription-list desktop-wide m-b-l" :class="[{ 'm-b-4xl': hasStickyBasket }]">
    <div v-for="(productList, j) in fields.ProductLists" :key="j">
      <transition name="fade-slide">
        <ScText
          v-if="titlesVisible[j]"
          tag="h2"
          class="text-md-l text-center text-l"
          :field="productList.fields.ProductListTitle"
        />
      </transition>
      <div class="subscription-list__product-list">
        <transition-group name="fade-slide" tag="div" class="product-tile__list">
          <div
            class="product-tile"
            v-for="product in productListTemp[j]"
            :key="product.id"
            :data-tile="product.id"
            :class="[
              { promoted: product.fields.ProductPromotion, discount: isDiscountPrice, expanded: isProductExpanded(product.id) },
            ]"
          >
            <div
              class="product-tile__head btn"
              tabindex="0"
              @mouseup="setMouseFocus($event)"
              @keyup.tab="setTabFocus(true)"
              @blur="setTabFocus(false)"
              @click="toggle(product.id, $event)"
              @keydown.enter.space.prevent="toggle(product.id, $event)"
              :class="{ 'tab-focus': isTabFocused }"
              aria-live="polite"
            >
              <div class="product-tile__left">
                <h3 class="product-tile__left-title title-xxs title-md-xxs">{{ product.fields.Title.value }}</h3>
                <p v-if="usersCount" class="text-xs">{{ sharedByAmountPersonsText }}</p>
              </div>

              <div class="product-tile__right">
                <div class="product-tile__right-texts">
                  <div :class="[{ 'animation-grow': animatePrices }]" class="product-tile__right-price">
                    <strong class="title-md-xxs title-xxs">{{ getProductPriceText({ product }) }}</strong>
                    <span class="postfix text-xs">{{ $t('price-postfix') }}</span>
                  </div>
                  <s v-if="isDiscountPrice" class="text-xs"
                    >{{ $t('before') }} {{ getProductPriceText({ product, getDefault: true }) }}{{ $t('price-postfix') }}</s
                  >
                </div>

                <div class="product-tile__right-icon">
                  <span class="icon"></span>
                </div>
              </div>

              <div v-if="hasPromotion(product)" key="" :class="getPromotionAlignment(product)" class="product-tile__head-on-top">
                <span class="product-promotion" :class="getPromotionClass(product)">{{ getPromotionText(product) }}</span>
              </div>
            </div>

            <div class="product-tile__bottom">
              <hr />

              <div class="product-tile__bottom-content">
                <div class="product-tile__bottom-head">
                  <strong v-if="usersCount" class="text-s text-md-s flex m-b-xs">
                    {{ getSingleFamilyMemberPrice(product) }}
                  </strong>

                  <div class="flex flex-row justify-content--space-between">
                    <ScText tag="h4" class="title text-s text-md-s" :field="product.fields.Description" />
                    <i
                      v-if="product.fields.InfoText.value"
                      @click="toggleExtraProductInfo($event)"
                      @keydown.enter.space.prevent="toggleExtraProductInfo($event)"
                      tabindex="0"
                      role="button"
                      :aria-controls="'bottomContent-' + product.id"
                      class="icon-info"
                    ></i>
                  </div>
                </div>

                <div class="product-tile__bottom-content__description m-b-s text-s text-md-s" :id="'bottomContent-' + product.id">
                  <div class="main default" aria-hidden="false">
                    <SpotList
                      v-if="hasSpots(product)"
                      :spots="product.fields.Spots"
                      class="spots"
                      :imgsize="'24px'"
                      :desktopImgsize="'32px'"
                    />
                  </div>

                  <div class="additional hidden" v-if="product.fields.InfoText.value" aria-hidden="true">
                    <ScText tag="p" :field="product.fields.InfoText" />
                  </div>
                </div>
                <div v-if="showMultipleChoicePicker" class="user-selector" :class="[{ 'single-counter': showSingleCounter }]">
                  <hr />

                  <h4 v-if="showSingleCounter" class="text-m text-md-m text-align--center m-t-s m-md-t-m">
                    {{ $t('label-user-counter-single') }}
                  </h4>

                  <div class="user-selector__counter-list">
                    <div class="user-selector__counter-container">
                      <div class="user-selector__counter">
                        <button
                          :disabled="counters[product.id].Normal <= 0"
                          @click="changeCounter({ product, increment: false })"
                          :aria-label="ariaLabelCounter({ increment: false })"
                        >
                          <i aria-hidden class="icon icon-minus" />
                        </button>
                        <span
                          aria-live="assertive"
                          class="user-selector__counter-result title-xs"
                          :class="[{ 'text-m text-md-m': showSingleCounter }]"
                          >{{ getCounterResultNormalText(product) }}</span
                        >
                        <button
                          @click="changeCounter({ product, increment: true })"
                          :aria-label="ariaLabelCounter({ increment: true })"
                        >
                          <i aria-hidden class="icon icon-plus" />
                        </button>
                      </div>
                      <p v-if="!showSingleCounter" aria-hidden class="text-xs text-md-s">{{ $t('label-over-30-counter') }}</p>
                    </div>
                    <div class="divider" v-if="!showSingleCounter"></div>
                    <div class="user-selector__counter-container" v-if="!showSingleCounter">
                      <div class="user-selector__counter">
                        <button
                          :disabled="counters[product.id].U30 <= 0"
                          @click="changeCounter({ product, increment: false, isDiscount: true })"
                          :aria-label="ariaLabelCounter({ increment: false, isU30: true })"
                        >
                          <i aria-hidden class="icon icon-minus" />
                        </button>
                        <span aria-live="assertive" class="user-selector__counter-result title-xs">{{
                          counters[product.id].U30
                        }}</span>
                        <button
                          @click="changeCounter({ product, increment: true, isDiscount: true })"
                          :aria-label="ariaLabelCounter({ increment: true, isU30: true })"
                        >
                          <i aria-hidden class="icon icon-plus" />
                        </button>
                      </div>
                      <p aria-hidden class="text-xs text-md-s">{{ $t('label-under-30-counter') }}</p>
                    </div>
                  </div>
                </div>

                <div class="button-container">
                  <button
                    :disabled="disabled(product)"
                    class="btn primary-btn primary-xs-small primary-md-default"
                    @click="addProduct(product)"
                    :class="[{ 'i-button primary-selected': isSelected(product) }]"
                  >
                    <i v-if="isSelected(product)" class="icon icon-check-bold"></i>
                    <ScText tag="span" :field="getButtonText(product)" />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </transition-group>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { Text } from '@sitecore-jss/sitecore-jss-vue';
import { gtmSharedParams, gtmSharedEcommerceParams, trackItemList, getGtmItem, gtmViewItemListEvent } from '@/gtmTracking';
import { gtmEcommerceEvent } from '../../../Talkmore.Web.Vue.Shared/src/utils/gtmTracking';
import { getProductPriceFromRates, getProductIndex } from '../../../Talkmore.Web.Vue.Shared/src/utils';
import SpotList from './util/SpotList.vue';

export default {
  name: 'SubscriptionList',
  components: {
    ScText: Text,
    SpotList,
  },
  props: {
    fields: {
      type: Object,
    },
  },
  data() {
    return {
      counters: {},
      animatePrices: false,
      productListTemp: [],
      titlesVisible: [],
      isTabFocused: false,
      expandedProducts: [], // this is needed to keep track on expanded products, since products DOM can change (in partner flows especially for discount appearing/dissapearing)
    };
  },
  computed: {
    ...mapGetters({
      getRatePlans: 'config/getRatePlans',
      getRatePlansDefault: 'config/getRatePlansDefault',
      getItems: 'basket/getItems',
      usersCount: 'basket/getUsersCount',
      isRecruitmentOrderType: 'config/isRecruitmentOrderType',
      isPartnerOrderType: 'config/isPartnerOrderType',
      priceKey: 'config/getPriceKey',
      priceKeyDefault: 'config/getPriceKeyDefault',
      priceKeyNormal: 'config/getPriceKeyNormal',
      priceKeyU30: 'config/getPriceKeyU30',
      isDiscountForOnlyOneProduct: 'config/isDiscountForOnlyOneProduct',
      isPercentageDiscount: 'config/isPercentageDiscount',
      getCoupon: 'config/getCoupon',
    }),
    allProducts() {
      return this.fields.ProductLists.reduce((all, productList) => {
        const allProducts = all.concat(productList.fields.ProductListProducts);
        this.$store.dispatch('app/addItem', { key: 'allProducts', value: allProducts });
        return allProducts;
      }, []);
    },
    isDefaultPrice() {
      return this.$store.state.config?.useDefaultPrices;
    },
    isDiscountPrice() {
      if (this.isDiscountForOnlyOneProduct) return this.getItems.length === 0;
      return this.isPercentageDiscount || !this.isDefaultPrice;
    },
    hasStickyBasket() {
      return this.$store.state.config?.showStickyBasket;
    },
    showMultipleChoicePicker() {
      return this.fields.MultipleChoice?.value === true;
    },
    sharedByAmountPersonsText() {
      return this.$t('shared-by-x-persons').replace('{amount}', this.usersCount);
    },
    visible() {
      return !this.isRecruitmentOrderType || this.$store.state.app[this.fields.ToggleByStoreValue.value];
    },
    showSingleCounter() {
      return this.isPartnerOrderType && this.isDiscountForOnlyOneProduct;
    },
    itemsLength() {
      return this.getItems.length;
    },
  },
  watch: {
    usersCount() {
      this.animatePrices = true;
      setTimeout(() => {
        this.animatePrices = false;
      }, 450);
    },
    visible(newVal) {
      if (newVal) {
        this.$nextTick(() => {
          this.initializeComponent();
        });
      }
    },
    itemsLength(newVal, oldVal) {
      if (this.showMultipleChoicePicker) this.setCounters();
      if (this.isDiscountForOnlyOneProduct) {
        if (oldVal === 0 && newVal > 0) {
          this.updatePriceKey(this.priceKeyNormal);
        } else if (newVal === 0) {
          this.updatePriceKey(this.priceKeyDefault);
        }
      }
    },
  },
  created() {
    if (this.showMultipleChoicePicker) this.setCounters();
  },
  mounted() {
    if (this.visible) {
      this.initializeComponent();
    }
  },
  methods: {
    initializeComponent() {
      if (this.allProducts.length > 0) {
        trackItemList(this.$el, false);
      }

      this.initializeProductListTemp();
      this.initializeTitlesVisible();

      setTimeout(() => {
        this.animateTitlesAndProducts(0);
      }, 500);
    },
    initializeProductListTemp() {
      this.productListTemp = this.fields.ProductLists.map(() => []);
    },
    initializeTitlesVisible() {
      this.titlesVisible = this.fields.ProductLists.map(() => false);
    },
    animateTitlesAndProducts(listIndex) {
      if (listIndex >= this.fields.ProductLists.length) return;

      // Show the title
      this.$set(this.titlesVisible, listIndex, true);

      setTimeout(() => {
        this.addProductsSequentially(listIndex, 0);
      }, 200);
    },
    setCounters() {
      this.fields.ProductLists.forEach((list) => {
        list.fields.ProductListProducts.forEach((product) => {
          const defaultCount = this.getTotalDefaultCount(product); // pricekeys are set in AppRoot
          const discountedCount = this.getItemsCountByProductAndPriceKey(product, this.priceKeyU30);
          this.$set(this.counters, product.id, {
            displayName: product.displayName,
            Normal: defaultCount,
            U30: discountedCount,
            isActive: false,
          });
          if (this.showSingleCounter && defaultCount === 0) {
            this.$set(this.counters[product.id], 'isActive', true);
            this.$set(this.counters[product.id], this.priceKeyNormal, 1);
          }
        });
      });
    },
    addProductsSequentially(listIndex, productIndex) {
      const currentList = this.fields.ProductLists[listIndex].fields.ProductListProducts;

      if (productIndex < currentList.length) {
        this.productListTemp[listIndex].push(currentList[productIndex]);
        setTimeout(() => {
          this.addProductsSequentially(listIndex, productIndex + 1);
        }, 200);
      } else {
        // Move to the next list after finishing the current list
        setTimeout(() => {
          this.animateTitlesAndProducts(listIndex + 1);
        }, 200); // Delay before starting the next list
      }
    },
    getSingleFamilyMemberPrice(product) {
      return this.$t('single-member-price').replace(
        '{single-price}',
        this.getProductPriceTextPerPerson({ product }) + this.$t('price-postfix'),
      );
    },
    hasSpots(product) {
      return product?.fields?.Spots?.length;
    },
    hasPromotion(product) {
      return product?.fields?.ProductPromotion;
    },
    getPromotionClass(product) {
      const cssClass = product?.fields?.ProductPromotion?.fields?.BackgroundColorClass?.value;
      if (cssClass) return cssClass;
      return null;
    },
    getPromotionText(product) {
      const text = product?.fields?.ProductPromotion?.fields?.Text?.value;
      if (text) return text;
      return null;
    },
    ariaLabelCounter({ increment = true, isU30 = false }) {
      const action = increment ? this.$t('number-increase') : this.$t('number-decrease');
      let labelEnding = this.$t('persons');
      if (!this.showSingleCounter) {
        labelEnding = isU30 ? this.$t('label-under-30-counter') : this.$t('label-over-30-counter');
      }
      return `${action} ${labelEnding}`;
    },
    getButtonText(product) {
      if (this.isSelected(product)) {
        return this.fields.SubscriptionButtonTextAdded;
      } else if (this.showMultipleChoicePicker && this.counters[product.id].isActive) {
        const itemsNormal = this.getTotalDefaultCount(product);
        const itemsU30 = this.getItemsCountByProductAndPriceKey(product, this.priceKeyU30);

        if (itemsNormal === 0 && itemsU30 === 0) {
          return this.fields.SubscriptionButtonText;
        } else if (itemsNormal === this.counters[product.id].Normal && itemsU30 === this.counters[product.id].U30) {
          return this.fields.SubscriptionButtonTextAdded;
        }
        return this.fields.SubscriptionButtonTextChange;
      }
      return this.fields.SubscriptionButtonText;
    },
    isSelected(product) {
      if (!this.getItems?.length) return false;
      if (!this.showMultipleChoicePicker) {
        return this.getItems.some(
          (item) => item.product.id === product.id && item.priceKey === this.$store.state.config?.priceKey, // in single flow control based on pricekey
        );
      } else {
        const itemsNormal = this.getTotalDefaultCount(product);
        const itemsU30 = this.getItemsCountByProductAndPriceKey(product, this.priceKeyU30);
        if (itemsNormal === 0 && itemsU30 === 0) {
          return false;
        } else if (itemsNormal === this.counters[product.id].Normal && itemsU30 === this.counters[product.id].U30) {
          return true;
        }
      }
    },
    getItemsCountByProductAndPriceKey(product, priceKey) {
      return this.getItems.filter((item) => item.product.id === product.id && item.priceKey === priceKey).length;
    },
    getTotalDefaultCount(product) {
      const normalCount = this.getItemsCountByProductAndPriceKey(product, this.priceKeyNormal);
      if (!this.isDiscountForOnlyOneProduct) return normalCount;

      const otherCount = this.getItemsCountByProductAndPriceKey(product, this.priceKeyDefault);
      return normalCount + otherCount;
    },
    disabled(product) {
      if (
        this.showMultipleChoicePicker &&
        this.counters[product.id].Normal === 0 &&
        this.counters[product.id].U30 === 0 &&
        !this.isSelected(product) &&
        !this.counters[product.id].isActive
      )
        return true;
      return false;
    },
    addProduct(product) {
      const index = getProductIndex(this.allProducts, product);
      if (this.showMultipleChoicePicker) {
        this.counters[product.id].isActive = false;
        const u30Count = this.showSingleCounter ? null : this.counters[product.id].U30; // only send U30 count if u30 counter is visible
        this.$root.$emit('onSaveMultipleProducts', product, index, this.counters[product.id].Normal, u30Count);
      } else if (this.usersCount) {
        // familyflow
        if (this.getItems?.length !== 0) {
          const basketItem = this.$store.getters['basket/getBasket'].items[0];
          this.$root.$emit('onUpdateBasketItemProduct', { basketItem, product, index });
        } else {
          this.$root.$emit('onSaveMultipleProducts', product, index, this.usersCount);
        }
      } else {
        // singleflow
        this.$root.$emit('onSaveSingleProduct', product, index);
      }

      if (!this.usersCount) this.$root.$emit('onShowBasket');
    },
    scrollToElement(elmnt, expandableElement) {
      if (!elmnt) {
        console.error('Element not found');
        return;
      }
      const elementRect = elmnt.getBoundingClientRect();
      const viewportHeight = window.innerHeight;
      const combinedHeight = elementRect.height + expandableElement.height;
      const targetScrollTop = window.scrollY + elementRect.top - viewportHeight / 2 + combinedHeight / 2;
      this.smoothScrollTo(targetScrollTop, 500);
    },
    smoothScrollTo(targetPosition, duration) {
      const start = window.scrollY;
      const startTime = performance.now();

      function scrollStep(timestamp) {
        const currentTime = timestamp || performance.now();
        const progress = Math.min((currentTime - startTime) / duration, 1);

        window.scrollTo(0, start + progress * (targetPosition - start));

        if (progress < 1) {
          requestAnimationFrame(scrollStep);
        }
      }

      requestAnimationFrame(scrollStep);
    },

    toggle(productId, event) {
      const dataTile = document.querySelector(`[data-tile="${productId}"]`);

      // current state of tile if expanded
      let expanded = !this.expandedProducts.includes(productId); // if it's not already open, we should open during this toggle

      if (expanded) {
        // opening
        this.expandedProducts.push(productId);
        const elementExpandable = event.target.parentNode.querySelector('.product-tile__bottom-content')?.getBoundingClientRect();
        if (elementExpandable) this.scrollToElement(event.target, elementExpandable);

        const product = this.allProducts.find((f) => f.id === productId);
        const coupon = this.getCoupon(this.priceKey);
        const price = getProductPriceFromRates({ product, ratePlans: this.getRatePlans });
        const gtmItemSelect = getGtmItem({ product, price, coupon });
        const gtmItemView = getGtmItem({ product, price, coupon, isAdding: false });
        gtmEcommerceEvent({
          event: 'select_item',
          items: [gtmItemSelect],
          gtmSharedParams: gtmSharedParams(),
          gtmSharedEcommerceParams: gtmSharedEcommerceParams(),
        });
        gtmEcommerceEvent({
          event: 'view_item',
          items: [gtmItemView],
          gtmSharedParams: gtmSharedParams(),
        });

        // adding default to "main" in description, due to animation fix for opening the tile combined with the extra additional info
        dataTile.querySelector('.main').classList.add('default');
      } else {
        // closing
        this.expandedProducts = this.expandedProducts.filter((id) => id !== productId);
      }
    },
    isProductExpanded(productId) {
      // defines if the product should have the extended class
      return this.expandedProducts.includes(productId);
    },
    getProductPriceTextPerPerson({ product }) {
      const ratePlans = this.getRatePlans;
      const familyCrmItem = this.$store.state.config?.familyCrmItem;
      const familyCrmItemPrice = familyCrmItem?.priceDiscount ? familyCrmItem?.priceDiscount : familyCrmItem?.price;
      const price = this.getProductPricePerPersonFromRates({ product, ratePlans, familyCrmItemPrice });
      return price + this.$t('price-denote');
    },
    getProductPricePerPersonFromRates({ product, ratePlans, familyCrmItemPrice = null }) {
      const id = product?.fields['CRM Item']?.value;
      if (!id) return null;

      if (typeof familyCrmItemPrice !== 'number' || familyCrmItemPrice <= 0) familyCrmItemPrice = null;

      const rateplan = ratePlans.find((f) => f.crmId === id);
      let price = Math.round(rateplan?.price);
      if (familyCrmItemPrice) price = (price + familyCrmItemPrice * this.usersCount) / this.usersCount;
      price = Math.floor(price);
      return price;
    },
    getProductPriceText({ product, getDefault = false }) {
      const ratePlans = getDefault ? this.getRatePlansDefault : this.getRatePlans;
      const familyCrmItem = this.$store.state.config?.familyCrmItem;
      const familyCrmItemPrice =
        familyCrmItem?.priceDiscount && !getDefault ? familyCrmItem?.priceDiscount : familyCrmItem?.price;
      const price = getProductPriceFromRates({ product, ratePlans, multiplyBy: this.usersCount, familyCrmItemPrice });
      return price + this.$t('price-denote');
    },
    getCounterResultNormalText(product) {
      const count = this.counters[product.id].Normal;
      if (!this.showSingleCounter) return count;
      const personText = count === 1 ? this.$t('person') : this.$t('persons');
      return `${count} ${personText}`;
    },
    changeCounter({ product, isDiscount = false, increment = true }) {
      const counter = this.counters[product.id];
      counter.isActive = true;

      const priceKey = isDiscount ? this.priceKeyU30 : this.priceKeyNormal;

      if (increment) {
        counter[priceKey]++;
      } else {
        if (counter[priceKey] > 0) {
          counter[priceKey]--;
        }
      }
      const key = priceKey === this.priceKeyU30 ? priceKey : this.priceKeyDefault; // treat other price keys (such as Partner) than U30 as "Normal" to fit with logic elsewhere

      const count = this.isDiscountForOnlyOneProduct
        ? this.getTotalDefaultCount(product)
        : this.getItemsCountByProductAndPriceKey(product, key);

      if (counter[priceKey] === count) {
        counter.isActive = false;
      }
    },
    getPromotionAlignment(product) {
      const alignment = product?.fields.ProductPromotion?.fields.Alignment?.value.toLowerCase();
      return alignment ? `position-${alignment}` : '';
    },
    setTabFocus(val) {
      this.isTabFocused = val;
    },
    setMouseFocus(event) {
      this.setTabFocus(false);
      event.currentTarget.blur();
    },
    async updatePriceKey(priceKey) {
      await this.$store.dispatch('config/updatePriceKey', priceKey);
      await this.$store.dispatch('config/updateRatePlanPrices');
      gtmViewItemListEvent();
    },
    toggleExtraProductInfo(event) {
      const productTile = event.currentTarget.closest('.product-tile__bottom-content');
      const mainContent = productTile.querySelector('.main');
      const additionalContent = productTile.querySelector('.additional');

      const isMainHidden = mainContent.classList.contains('hidden');

      // Toggle visibility
      mainContent.classList.toggle('hidden', !isMainHidden);
      additionalContent.classList.toggle('hidden', isMainHidden);
      mainContent.classList.remove('default');

      // Update aria-hidden attributes
      mainContent.setAttribute('aria-hidden', isMainHidden ? 'false' : 'true');
      additionalContent.setAttribute('aria-hidden', isMainHidden ? 'true' : 'false');
    },
  },
};
</script>

<style lang="scss" scoped>
.fade-slide-enter-active,
.fade-slide-leave-active {
  transition: opacity 1.5s, transform 0.5s;
}
.fade-slide-enter,
.fade-slide-leave-to {
  opacity: 0;
  transform: translateY(32px);
}

.subscription-list {
  display: flex;
  flex-direction: column;
  gap: 24px 0;

  @include desktop {
    margin-top: 24px;
  }

  &__product-list {
    gap: 16px 0;
    margin-top: 24px;
    display: flex;
    flex-direction: column;
  }

  // everything within the tile
  .product-tile {
    position: relative;
    background-color: #{$color-white};
    @include box-shadow(0px 2px 10px rgba(0, 0, 0, 0.1));
    border: 1px solid #e5e5e5;
    border-radius: 16px;
    @include transition(0.3s);
    transform-origin: center top;

    &__list {
      display: flex;
      flex-direction: column;
      gap: 16px 0;
    }
    // the top section of the tile
    &__head {
      display: flex;
      flex-direction: row;
      gap: $spacing-m;
      padding: 20px $spacing-m;
      outline: none;
      @include transition(0.15s);

      &-on-top {
        position: absolute;
        top: -8px;
        left: 32px; // default alignment
        line-height: normal;
        pointer-events: none;

        &.position {
          &-center {
            left: 50%;
            transform: translateX(-50%);
          }
          &-right {
            right: 32px;
            left: auto;
          }
        }
        .product-promotion {
          @include transition(0.15s);
        }
      }
    }

    &__left {
      display: flex;
      flex-direction: column;
      justify-content: center;
      flex: 1;
      align-items: flex-start;
      pointer-events: none;
      &-title {
        @include transition(0.2s);
      }
    }

    &__right {
      display: flex;
      align-items: center;
      gap: 16px;
      pointer-events: none;

      &-texts {
        gap: 4px;
        display: flex;
        flex-direction: column;
        align-items: flex-end;

        .postfix {
          padding-left: 4px;
        }
      }
      &-price {
        @include transition(0.2s);
      }
      &-icon {
        @extend .icon-down;
      }
    }

    // the hidden bottom section of the tile
    &__bottom {
      visibility: hidden;
      max-height: 0;
      overflow: hidden;
      @include transition(0.3s);

      &-head {
        margin-bottom: 12px;

        .icon-info {
          font-style: normal;
          font-size: 32px;
          display: flex;
          line-height: normal;
          padding: 10px;
          margin: -10px;
          @include infoButtonEffects;
          @include infoButtonIcon();
        }

        .info {
          display: flex;
          padding: 8px; // for the size of the clickable area
          margin: -8px; // nagating the right padding for the alignment
          flex-shrink: 0;
          font-size: 34px;
          width: 42px;
          height: 42px;
          justify-content: center;

          .icon {
            display: flex;
            align-content: center;
            justify-content: center;
            flex-direction: column;
          }

          &:hover {
            cursor: pointer;
          }
        }
      }

      hr {
        border: none;
        border-top: 1px solid #e5e5e5;
        width: Calc(100% - #{48px});
        margin: 0 auto;
      }

      &-content {
        padding: 12px 24px 16px;
        @include desktop {
          padding: 12px $spacing-m $spacing-m;
        }
      }

      h5 {
        font-size: 15px;
        font-family: $font-bold;
        line-height: 22.5px;
        display: flex;
        align-items: center;
      }
      .user-selector {
        &__counter-result {
          width: 33px;
          text-align: center;
          font-family: $font-bold;
          line-height: normal;
        }
        .divider {
          border-left: 1px solid #{$color-grey};
          height: 49px;
        }

        &.single-counter {
          .user-selector__counter {
            gap: 0 $spacing-xs;

            &-container {
              max-width: initial;
            }

            &-result {
              width: 110px;
              font-family: $font-normal;
              line-height: normal;
            }
          }
        }
      }

      .button-container {
        display: flex;
        justify-content: center;

        button {
          min-width: 133px;
          width: 100%;
          height: 47px;
          @include screen-tablet-portrait-up {
            height: 58px;
          }
        }
      }

      .product-tile__bottom-content {
        overflow: hidden;
        transition: max-height 0.4s ease;
        &__description {
          min-height: 50px;
          overflow: hidden;
        }
        .main.default {
          transition-delay: 0s !important; // nessecary due to that we don't want the transition delay, when opening the entire product-tile
        }

        .main,
        .additional {
          opacity: 1;
          max-height: 400px;
          transition: all 0.3s ease-in-out;
          transition-delay: 0.3s;

          &.hidden {
            max-height: 0;
            overflow: hidden;
            opacity: 0;
            transition: all 0.3s ease-in-out;
          }
        }
      }
    }

    &.discount {
      .product-tile__right-price strong {
        color: $color-green-darker;
      }
    }

    &.promoted {
      margin-top: 4px;

      .product-tile__head {
        padding-top: $spacing-m;
      }
    }

    // open tile styling
    &.expanded {
      @include transition(0.3s);

      .product-tile__bottom {
        visibility: visible;
        max-height: 900px;
        @include transition(0.3s);
      }

      .product-tile__right {
        &-icon {
          @extend .icon-up;
        }
      }
    }
    // desktop effects
    @include desktop {
      &:hover:not(.expanded) {
        @include box-shadow(0px 3px 10px rgba(0, 0, 0, 0.1));
        transform: scale(1.045);
        @include transition(0.15s);
        .product-tile__left-title {
          transform: scale(1.07);
          @include transition(0.15s);
        }
        .product-tile__right-price {
          transform: scale(1.15);
          @include transition(0.15s);
        }
        .product-promotion {
          font-size: 15px;
          @include transition(0.15s);
        }
      }
      &:focus-within {
        box-shadow: none;
        transform: scale(1.045);
        @include transition(0.15s);
        border-radius: $spacing-s;
        .product-tile__head {
          &.tab-focus {
            outline: 2px solid black;
            border-radius: 16px;
          }
        }
        &:hover {
          .product-tile__left-title {
            transform: scale(1);
          }
          .product-tile__right-price {
            transform: scale(1);
          }
          .product-promotion {
            font-size: 14px;
          }
        }
      }

      &.expanded {
        @include desktop {
          @include box-shadow(0px 3px 10px rgba(0, 0, 0, 0.1));
          transform: scale(1.045);
          margin-bottom: $spacing-m;
        }
        @include transition(0.15s);
        .product-promotion {
          font-size: 15px;
          @include transition(0.15s);
        }
      }
    }
  }
}

// animation
</style>
